















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { apiShopLists } from "@/api/shop";
import DatePicker from "@/components/date-picker.vue";

import { getLongRangeGoodsMscGoodsLists } from "@/api/goods";
import { deepClone } from "@/utils/util.ts";
import { apiLists } from '@/api/settlement'

@Component({
    components: {
        lsPagination,
        LsDialog,
        DatePicker,
    },
})
export default class MyselectionLibraryIndex extends Vue {
  apiLists = apiLists;

    pager: RequestPaging = new RequestPaging();
    count: any = {
        inStore: 0,
        newThisMonth: 0,
        onSale: 0,
        soldOut: 0,
    };
    searchObj: any = {
        sn: "",
        year: '',
    };

    //选品库数据
    getGoodsLists() {
        this.pager
            .request({
                callback: apiLists,
                params: this.searchObj,
            })
            .then((res) => {
                this.count = res.extend;
            });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getGoodsLists();
    }

    //查看详情
    viewDetails(row: any) {
        this.$router.push({
            path: "/finance/platform_settlement_detail",
            query: {
                id: row.id,
                year: row.year,
                month: row.month,
              amount: row.amount,
              settlement_sn: row.settlement_sn,
                page: this.pager.page,
                size: this.pager.size,
            },
        });
    }

    /** S ods **/
    async created() {
        this.pager.page = Number(this.$route.query.page) || 1;
        this.pager.size = Number(this.$route.query.size) || 10;
        this.getGoodsLists();
    }
}
