







































































import { RequestPaging } from "@/utils/util";
import { Component, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import not_yet_lists from "./not_yet_lists.vue";
import yet_lists from "./yet_lists.vue";
import { apiBasicInfo } from '@/api/settlement'
@Component({
    components: {
        LsDialog,
        not_yet_lists,
        yet_lists,
    },
})

export default class Overview extends Vue {
    activeName: any = "0";
    basicInfo: any = {
      lock_balance:0,
      now_balance:0,
      settlementNotPaidPrice:0
    }
    async created() {
        const t = this.$route.query.t || null;
        if (t) {
            this.activeName = t;
        }
        await this.getBasicInfo()
    }

    async getBasicInfo() {
        const res = await apiBasicInfo({})
        this.basicInfo = res
    }
}
