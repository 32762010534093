
//电影院列表
import request from '@/plugins/axios'

/**
 * 账户信息
 * @param params
 */
export const apiBasicInfo = (params: any) =>
  request.get('/finance.Settlement/basicInfo', { params })

/**
 * 结算单列表
 * @param params
 */
export const apiLists = (params: any) =>
  request.get('/finance.Settlement/lists', { params })
/**
 * 未结算
 * @param params
 */
export const apiNotPaidList = (params: any) =>
  request.get('/finance.Settlement/notPaidList', { params })

/**
 * 结算单详情
 * @param params
 */
export const apiInfo = (params: any) =>
  request.get('/finance.Settlement/info', { params })

// //添加产品档案啊
// export const apiAddProductProfile = (data: any) => request.post('/card.product_file/add', data)
