













































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { apiShopLists } from "@/api/shop";
import DatePicker from "@/components/date-picker.vue";
import { getLongRangeGoodsMscGoodsLists } from "@/api/goods";

import { deepClone } from "@/utils/util.ts";
import { apiNotPaidList } from "@/api/settlement";

@Component({
    components: {
        lsPagination,
        LsDialog,
        DatePicker,
    },
})
export default class MyselectionLibraryIndex extends Vue {
    apiNotPaidList = apiNotPaidList;
    pager: RequestPaging = new RequestPaging();
    count: any = {
        inStore: 0,
        newThisMonth: 0,
        onSale: 0,
        soldOut: 0,
    };
    searchObj: any = {
        order_sn: "",
        local_sn: "",
        deduction_type: "",
        order_type: "",
        start_date: "",
        end_date: "",
    };

    //选品库数据
    getGoodsLists() {
        const searchObj = this.searchObj;

        this.pager
            .request({
                callback: apiNotPaidList,
                params: searchObj,
            })
            .then((res) => {
                this.count = res.extend;
            });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getGoodsLists();
    }

    /** S ods **/
    async created() {
        this.pager.page = Number(this.$route.query.page) || 1;
        this.pager.size = Number(this.$route.query.size) || 10;
        this.getGoodsLists();
    }
}
